import { sumBy } from 'lodash'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import { useId, useState } from 'react'
import { Button } from '@/components/Button'
import { cn } from '@/utils/cn'
import { printLocalAmount } from '@/utils/misc'

export interface LoanAmountCardProps {
  className?: string
  ctaText: string
}

export default function LoanAmountCard(props: LoanAmountCardProps) {
  const { t } = useTranslation()

  const inputId = useId()
  const [loanAmount, _setLoanAmount] = useState(5000)
  const [inputValue, setInputValue] = useState(() =>
    printLocalAmount({
      number: loanAmount,
      fractionDigits: 0,
      removeSpaces: true,
    }),
  )

  const setLoanAmount = (amount: number) => {
    _setLoanAmount(amount)
    setInputValue(
      printLocalAmount({
        number: amount,
        fractionDigits: 0,
        removeSpaces: true,
      }),
    )
  }

  const sliderValue = loanAmountToSliderValue(loanAmount)

  return (
    <form
      className={cn(
        'p-3 rounded-xl flex flex-col items-center shadow-xl gap-y-2 max-w-80 bg-white',
        props.className,
      )}
    >
      <label
        className="text-primary font-semibold leading-[140%]"
        htmlFor={inputId}
      >
        {t('common:choose_loan_amount')}
      </label>

      {/** Safari < 17 does not support line-height in inputs */}
      <div className="border border-neutral-900 rounded-xl h-14 flex justify-center items-center overflow-hidden">
        <input
          className="w-full tabular-nums text-brand-600 text-5xl font-bold text-center outline-none"
          id={inputId}
          inputMode="numeric"
          name="loan-amount"
          value={inputValue}
          onFocus={(event) => {
            event.target.setSelectionRange(0, event.target.value.length - 1)
          }}
          onKeyDown={(event) => {
            const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight']
            if (
              event.ctrlKey ||
              event.metaKey ||
              allowedKeys.includes(event.key)
            ) {
              return
            }

            if (
              event.currentTarget.value.endsWith('€') &&
              event.currentTarget.value.length ===
                event.currentTarget.selectionEnd
            ) {
              if (
                event.currentTarget.selectionStart ??
                0 >= event.currentTarget.selectionEnd - 1
              ) {
                event.preventDefault()
              } else {
                event.currentTarget.selectionEnd--
              }
            }

            if (!event.key.match(amountInputAllowedRegEx)) {
              event.preventDefault()
            }
          }}
          onPaste={(event) => {
            const text = event.clipboardData.getData('text')

            if (!text.match(amountInputAllowedRegEx)) {
              event.preventDefault()
            }
          }}
          onChange={(event) => {
            setInputValue(event.target.value)
          }}
          onBlur={(event) => {
            const value = Number.parseFloat(
              event.target.value.replaceAll('.', '').replaceAll(',', '.'),
            )

            if (Number.isNaN(value)) {
              return
            }

            const cappedAmount = Math.max(
              MIN_CAR_PAYOUT,
              Math.min(MAX_CAR_PAYOUT, value),
            )
            setLoanAmount(
              sliderValueToLoanAmount(loanAmountToSliderValue(cappedAmount)),
            )
          }}
        />
      </div>

      <input
        type="range"
        name="loan-amount-step"
        className="w-full car-hero-slider"
        aria-hidden
        tabIndex={-1}
        min={0}
        max={totalSteps}
        value={sliderValue}
        style={
          {
            '--slider-value-percent': `${((sliderValue / totalSteps) * 100).toFixed(2)}%`,
          } as React.CSSProperties
        }
        onChange={(event) => {
          setLoanAmount(sliderValueToLoanAmount(event.target.valueAsNumber))
        }}
      />

      <Button variant="cta" asChild className="w-full">
        <Link
          href={{
            pathname: '/autopfand-formular',
            query: { betrag: loanAmount },
          }}
        >
          {props.ctaText}
        </Link>
      </Button>
    </form>
  )
}

const amountInputAllowedRegEx = /^[\d.]+$/

export const MIN_CAR_PAYOUT = 500
export const MAX_CAR_PAYOUT = 200_000

const stepRanges = [
  {
    to: 5_000,
    step: 100,
  },
  {
    to: 20_000,
    step: 500,
  },
  {
    to: 60_000,
    step: 1_000,
  },
  {
    to: 120_000,
    step: 2_500,
  },
  {
    to: MAX_CAR_PAYOUT,
    step: 5_000,
  },
].map((sr, i, srs) => ({
  ...sr,
  steps: (sr.to - (i > 0 ? srs[i - 1].to : MIN_CAR_PAYOUT)) / sr.step,
}))

function loanAmountToSliderValue(loanAmount: number): number {
  if (loanAmount <= MIN_CAR_PAYOUT) {
    return 0
  }

  for (let i = stepRanges.length - 1; i >= -1; i--) {
    const to = i >= 0 ? stepRanges[i].to : MIN_CAR_PAYOUT
    if (loanAmount > to) {
      return (
        sumBy(stepRanges.slice(0, i + 1), 'steps') +
        Math.ceil((loanAmount - to) / stepRanges[i + 1].step)
      )
    }
  }

  throw new Error()
}

function sliderValueToLoanAmount(sliderValue: number): number {
  let loanAmount = MIN_CAR_PAYOUT
  for (const { step, steps } of stepRanges) {
    loanAmount += Math.min(sliderValue, steps) * step
    sliderValue -= steps
    if (sliderValue <= 0) {
      return loanAmount
    }
  }

  return MAX_CAR_PAYOUT
}

const totalSteps = sumBy(stepRanges, 'steps')
